<template>
  <div class="sctp-bg-white pad15">
    <el-row v-loading="isInitData" :gutter="10" style="min-height: 100px;">
      <template v-if="status">
        <el-col :span="6">
          <div class="card">
            <h1>我的源码数</h1>
            <div class="sctp-bold">{{ status.sourceCodeCount || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <h1>我的文档数</h1>
            <div class="sctp-bold">{{ status.documentCount || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <h1>我的设计数</h1>
            <div class="sctp-bold">{{ status.designCount || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <h1>我的服务数</h1>
            <div class="sctp-bold">{{ status.serviceCount || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <h1>累计成交商品</h1>
            <div class="sctp-bold">{{ status.countDeal || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <h1>累计成交金额</h1>
            <div class="sctp-bold">{{ (status.sumDealAmount || 0).toMoney() }}</div>
          </div>
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import {shop} from "@/apis";

export default {
  name: "statistics",
  data() {
    return {
      isInitData: false,
      status: null,
    }
  },
  methods: {
    loadData() {
      this.isInitData = true;
      shop.shopStatistics({
        userId: this.user.userId
      }).then(res => {
        let {data} = res;
        this.status = data;
      }).finally(() => {
        this.isInitData = false;
      });
    }
  },
  beforeMount() {
    this.loadData();
  }
}
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-color: #F8F8F8;

  h1 + div {
    font-size: 24px;
  }

  h1 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
</style>
